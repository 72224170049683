// @ts-check
import { moduleServiceConfig } from '@/services/application-service/moduleRequests';
import { NODE_RESOURCE_PATH } from '@/services/application-service/applicationServiceConstants';

const moduleNodesServiceConfig = {
    ...moduleServiceConfig,
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     * @param {string} nodeId
     */
    getNodesResourcePath (applicationId, moduleId, nodeId) {
        return `${this.getModuleResourcePath(applicationId, moduleId)}${NODE_RESOURCE_PATH}/${nodeId}`;
    }
};

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {string} nodeId 
 */
export const fetchModuleNodeService = (applicationId, moduleId, nodeId) => moduleNodesServiceConfig.http({
    method: 'GET',
    url: `${moduleNodesServiceConfig.getNodesResourcePath(applicationId, moduleId, nodeId)}`
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {string} nodeId
 * @param {object} data
 */
export const addModuleNodeService = (applicationId, moduleId, nodeId, data) => moduleNodesServiceConfig.http({
    method: 'POST',
    url: `${moduleNodesServiceConfig.getNodesResourcePath(applicationId, moduleId, nodeId)}`,
    data
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {string} nodeId
 * @param {object} data
 */
export const updateModuleNodeService = (applicationId, moduleId, nodeId, data) => moduleNodesServiceConfig.http({
    method: 'PUT',
    url: `${moduleNodesServiceConfig.getNodesResourcePath(applicationId, moduleId, nodeId)}`,
    data
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {string} nodeId 
 */
export const deleteModuleNodeService = (applicationId, moduleId, nodeId) => moduleNodesServiceConfig.http({
    method: 'DELETE',
    url: `${moduleNodesServiceConfig.getNodesResourcePath(applicationId, moduleId, nodeId)}`
});
