import { createPubSub } from '@/helpers/pubSub';

export const subscription = createPubSub();

// events to subscribe
/** Trigger this event when connection connecting parent and child nodes is deleted */
export const ON_PARENT_CHILD_CONNECTION_DELETE = 'onParentChildConnectionDelete';
/** Trigger this event when a dangling (detached) node is connected back to tree */
export const ON_DANGLING_NODE_CONNECT = 'onDanglingNodeConnect';
/** Trigger this event when connections needs to be updated in UI */
export const ON_CONNECTIONS_UPDATE = 'onConnectionsUpdate';
/** Trigger this event when a connection is selected */
export const ON_CONNECTION_SELECT = 'onConnectionSelect';
