/**
 * Creates a pub/sub model
 */
export const createPubSub = () => {
    const subscribers = {};

    /**
     * Subscribes handler with an event
     * @param {string} eventName 
     * @param {function} callback 
     */
    const subscribe = (eventName, callback) => {
        if (!subscribers[eventName]) {
            subscribers[eventName] = [];
        }
        const index = subscribers[eventName].push(callback);
        const unsubscribe = () => {
            subscribers[eventName].splice(index);
        };
        return unsubscribe;
    };
    

    /**
     * Triggers all handlers for a subscribed event with provided data
     * @param {string} eventName
     * @param {*} data
     */
    const publish = (eventName, data) => {
        if (subscribers[eventName]) {
            subscribers[eventName].forEach(callback => callback(data));
        }
    };

    return {
        subscribers,
        subscribe,
        publish
    };
};
