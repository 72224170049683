// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH, MODULE_RESOURCE_PATH, MODULE_DOCUMENTS_RESOURCE_PATH, GLOBAL_VARIABLES_RESOURCE_PATH, REVERT_RESOURCE_PATH, GUARD_MODULE_RESOURCE_PATH, UNGUARD_MODULE_RESOURCE_PATH } from './applicationServiceConstants';

export const moduleServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId 
     * @param {string} [moduleId] 
     */
    getModuleResourcePath: (applicationId, moduleId = '') => `/${applicationId}${MODULE_RESOURCE_PATH}${moduleId ? '/' : ''}${moduleId}`
};

//-- request interceptors --//
moduleServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId
 * @param {import('./applicationServiceTypes').IFetchModulesParams} [params]
 */
export const fetchModulesService = (applicationId, params = {}) => moduleServiceConfig.http({
    method: 'GET',
    url: moduleServiceConfig.getModuleResourcePath(applicationId),
    params
});

/**
 * @param {string} applicationId
 * @param {object} data
 */
export const createModuleService = (applicationId, data) => moduleServiceConfig.http({
    method: 'POST',
    url: moduleServiceConfig.getModuleResourcePath(applicationId),
    data
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const copyModuleService = (applicationId, moduleId, data) => moduleServiceConfig.http({
    method: 'POST',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}/copy`,
    data
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 * @param query
 */
export const updateModuleService = (applicationId, moduleId, data, query = '') => moduleServiceConfig.http({
    method: 'PUT',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${query}`,
    data
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId
 */
export const fetchModuleByIdService = (applicationId, moduleId) => moduleServiceConfig.http({
    method: 'GET',
    url: moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId
 */
export const deleteModuleService = (applicationId, moduleId) => moduleServiceConfig.http({
    method: 'DELETE',
    url: moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId
 */
export const fetchModuleDocumentsService = (applicationId, moduleId) => moduleServiceConfig.http({
    method: 'GET',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${MODULE_DOCUMENTS_RESOURCE_PATH}`
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId
 * @param {object} data
 */
export const updateGlobalVariablesInfoService = (applicationId, moduleId, data) => moduleServiceConfig.http({
    method: 'PUT',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${GLOBAL_VARIABLES_RESOURCE_PATH}`,
    data
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const revertModuleService = (applicationId, moduleId, data) => moduleServiceConfig.http({
    method: 'PUT',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${REVERT_RESOURCE_PATH}`,
    data
});

/**
 *
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const guardModuleService = (applicationId, moduleId, data) => moduleServiceConfig.http({
    method: 'PUT',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${GUARD_MODULE_RESOURCE_PATH}`,
    data
});

/**
 *
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {object} data
 */
export const unguardModuleService = (applicationId, moduleId, data) => moduleServiceConfig.http({
    method: 'PUT',
    url: `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${UNGUARD_MODULE_RESOURCE_PATH}`,
    data
});
