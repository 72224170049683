// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH, VARIABLE_RESOURCE_PATH, MODULE_RESOURCE_PATH } from './applicationServiceConstants';

const variableServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     */
    getVariableResourcePath: (applicationId, moduleId) => `${applicationId}${MODULE_RESOURCE_PATH}/${moduleId}${VARIABLE_RESOURCE_PATH}`
};

//-- request interceptors --//
variableServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId 
 * @param {string} moduleId 
 * @param {import('../../types/applicationTypes').IVariablePayload} data
 */
export const createVariableService = (applicationId, moduleId, data) => variableServiceConfig.http({
    method: 'POST',
    url: variableServiceConfig.getVariableResourcePath(applicationId, moduleId),
    data
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId
 * @param {import('./applicationServiceTypes').IDeleteVariableNodeParams} params  
 */
export const deleteVariableWithNodeService = (applicationId, moduleId, params) => variableServiceConfig.http({
    method: 'DELETE',
    url: variableServiceConfig.getVariableResourcePath(applicationId, moduleId),
    params
});

/**
 * @param {string} applicationId
 * @param {string} moduleId
 */
export const fetchVariablesService = (applicationId, moduleId) => variableServiceConfig.http({
    method: 'GET',
    url: variableServiceConfig.getVariableResourcePath(applicationId, moduleId)
});

/**
 * @param {string} applicationId 
 * @param {string} moduleId 
 * @param {import('./applicationServiceTypes').IDeleteVariableReferenceParams} params 
 */
export const deleteVariableWithReferenceService = (applicationId, moduleId, params) => variableServiceConfig.http({
    method: 'DELETE',
    url: variableServiceConfig.getVariableResourcePath(applicationId, moduleId),
    params
});
