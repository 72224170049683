// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { VERSION_CONTROL_SERVICE_ENDPOINT, VERSION_CONTROL_NODE_RESOURCE_PATH } from './versionControlServiceConstants';

const versionControlNodeServiceConfig = {
    ...createHttpServiceConfig(VERSION_CONTROL_SERVICE_ENDPOINT, VERSION_CONTROL_NODE_RESOURCE_PATH),
    /**
     * @param {string} moduleId
     * @param {string} nodeId
     * @param {string} versionId
     */
    getNodeTagResourcePath: (moduleId, nodeId, versionId) => `${moduleId}/node/${nodeId}/version/${versionId}/tag-node`
};

//-- request interceptors --//
versionControlNodeServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} moduleId
 * @param {string} nodeId
 * @param {string} versionId
 * @param {object} data
 */
export const createNodeTagService = (moduleId, nodeId, versionId, data) => versionControlNodeServiceConfig.http({
    method: 'POST',
    url: versionControlNodeServiceConfig.getNodeTagResourcePath(moduleId, nodeId, versionId),
    data
});
