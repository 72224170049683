// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { DATABASE_RESOURCE_PATH, DATABASE_SERVICE_ENDPOINT } from './databaseServiceConstants';

const databaseServiceConfig = createHttpServiceConfig(DATABASE_SERVICE_ENDPOINT, DATABASE_RESOURCE_PATH);

//-- request interceptors --//
databaseServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('../../types/databaseTypes').IDatabase} data
 */
export const createDatabaseService = (data) => databaseServiceConfig.http({
    method: 'POST',
    data
});

/**
 * @param {import('./databaseServiceTypes').IFetchDatabasesParams} params 
 */
export const fetchDatabasesService = (params) => databaseServiceConfig.http({
    method: 'GET',
    params
});

/**
 * @param {string} id
 */
export const fetchDatabaseByIdService = (id) => databaseServiceConfig.http({
    method: 'GET',
    url: `/${id}`
});

/**
 * @param {string} id
 * @param {import('../../types/databaseTypes').IUpdateDatabase} data
 */
export const updateDatabaseService = (id, data) => databaseServiceConfig.http({
    method: 'PATCH',
    url: `/${id}`,
    data
});

/**
 * @param {string} id 
 */
export const deleteDatabaseService = (id) => databaseServiceConfig.http({
    method: 'DELETE',
    url: `/${id}`
});
