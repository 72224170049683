// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { LINT_SERVICE_ENDPOINT, MODULE_RESOURCE_PATH } from './lintServiceContants';

const lintModuleServiceConfig = {
    ...createHttpServiceConfig(LINT_SERVICE_ENDPOINT, MODULE_RESOURCE_PATH),
    /**
     * @param {string} modelId
     */
    getModuleResourcePath: (modelId) => `/${modelId}`,
};

//-- request interceptors --//
lintModuleServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} moduleId
 */
export const moduleLintingService = (moduleId) => lintModuleServiceConfig.http({
    method: 'GET',
    url: lintModuleServiceConfig.getModuleResourcePath(moduleId)
});
