// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import {
    APPLICATION_RESOURCE_PATH,
    APPLICATION_SERVICE_ENDPOINT,
    ENVIRONMENT_VARIABLE_RESOURCE_PATH
} from './applicationServiceConstants';

const envVariableServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId
     */
    getEnvVariableResourcePath: (applicationId) => `${applicationId}${ENVIRONMENT_VARIABLE_RESOURCE_PATH}`
};

//-- request interceptors --//
envVariableServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} applicationId
 * @param {import('../../types/applicationTypes').IVariablePayload} data
 */
export const createEnvironmentVariableService = (applicationId, data) => envVariableServiceConfig.http({
    method: 'POST',
    url: envVariableServiceConfig.getEnvVariableResourcePath(applicationId),
    data
});

/**
 * @param {string} applicationId
 * @param {import('./applicationServiceTypes').IDeleteEnvironmentVariableParams} params
 */
export const deleteEnvironmentVariable = (applicationId, params) => envVariableServiceConfig.http({
    method: 'DELETE',
    url: `${envVariableServiceConfig.getEnvVariableResourcePath(applicationId)}/${params.reference}`,
});

/**
 * @param {string} applicationId
 */
export const fetchEnvironmentVariablesService = (applicationId) => envVariableServiceConfig.http({
    method: 'GET',
    url: envVariableServiceConfig.getEnvVariableResourcePath(applicationId)
});

/**
 * @param {string} applicationId
 * @param {object} params
 * */
export const fetchSingleEnvironmentVariablesService = (applicationId, params) => envVariableServiceConfig.http({
    method: 'GET',
    url: `${envVariableServiceConfig.getEnvVariableResourcePath(applicationId)}/${params.reference}`,
});

/**
 * @param {string} applicationId
 * @param {object} params
 * @param {import('../../types/applicationTypes').IVariablePayload} data
 */
export const updateEnvironmentVariableService = (applicationId, params, data, ) => envVariableServiceConfig.http({
    method: 'PATCH',
    url: `${envVariableServiceConfig.getEnvVariableResourcePath(applicationId)}/${params.reference}`,
    data
});
