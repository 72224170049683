import { ON_CONNECTIONS_UPDATE, ON_DANGLING_NODE_CONNECT, subscription } from './treeFlowPubSub';

//-- private utility functions(meant for internal use within Tree Flow modules only) --//

/**
 * creates a bounding rect with positions relative to specified element
 * @param {DOMRect} boundingRect
 * @param {HTMLElement} element
 */
export const getRelativeBoundingRect = (boundingRect, element) => {
    const elementBoundingRect = element.getBoundingClientRect();
    return DOMRect.fromRect({
        x: boundingRect.x - elementBoundingRect.x,
        y: boundingRect.y - elementBoundingRect.y,
        width: boundingRect.width,
        height: boundingRect.height
    });
};

/**
 * Checks if a node is child of the specified parent
 * @param {string} parentNodeId
 * @param {object} childNode
 */
export const isChildNode = (parentNodeId, childNode) => {
    if (childNode) {
        const actualParentNodeId = childNode.position.split('/').slice(-1)[0];
        return actualParentNodeId === parentNodeId;
    }
    return false;
};

/**
 * Connects dangling node (node decoupled from tree due to deletion of its parent) back to tree.
 * @param {import('./types/tree').ITreeNode} sourceNode
 * @param {import('./types/tree').ITreeNode} targetNode
 * @param {import('./types/tree').ICondition} condition
 */
export const connectDanglingNode = (sourceNode, targetNode, condition) => {
    subscription.publish(ON_DANGLING_NODE_CONNECT, {
        sourceNodeId: sourceNode.nodeId,
        targetNodeId: targetNode.nodeId,
        condition
    });
};

/**
 * Checks if a node is detached from tree (dangling node)
 * @param {import('./types/tree').ITreeNode} node
 */
export const isDanglingNode = (node) => node.position === '' && !node.isStart;

/**
 * Returns connection object for specified connection id
 * @param {import('./types/tree').ITreeNode[]} treeNodes 
 * @param {string} connectionId 
 */
export const getConnectionById = (treeNodes, connectionId) => treeNodes.flatMap(node => node.connections).find(connection => connection.id === connectionId);

/**
 * Checks if a connection starts from a parent to its child
 * @param {import('./types/tree').ITreeNode[]} treeNodes
 * @param {string} connectionId
 */
export const checkParentChildConnection = (treeNodes, connectionId) => {
    let connection = null;
    const node = treeNodes.find(node => {
        connection = node.connections.find(connection => connection.id === connectionId);
        return connection;
    });
    const childNodes = getChildren(treeNodes, node);
    return childNodes.some(cNode => cNode.nodeId === connection.targetNodeId);
};

/**
 * Returns children of a node 
 * @param {import('./types/tree').ITreeNode[]} treeNodes
 * @param {import('./types/tree').ITreeNode} parentNode
 */
export const getChildren = (treeNodes, parentNode) => treeNodes.filter(node => node.position.split('/').slice(-1)[0] === parentNode?.nodeId);

/**
 * Returns all connections associated with a node
 * @param {import('./types/tree').ITreeNode[]} treeNodes 
 * @param {import('./types/tree').ITreeNode} node 
 */
export const getNodeConnections = (treeNodes, node) => {
    const treeConnections = treeNodes.filter(tNode => tNode.connections.length).flatMap(tNode => tNode.connections);
    const associatedNodeConnections = treeConnections.filter(connection => connection.targetNodeId === node.nodeId);
    return node.connections.concat(associatedNodeConnections);
};

/**
 * Removes specified connection from a node
 * @param {import('./types/tree').ITreeNode[]} treeNodes
 * @param {string} connectionId
 */
export const deleteConnectionFromTree = (treeNodes, connectionId) => {
    let connectionIndex = -1;
    const node = treeNodes.find(node => {
        const cIndex = node.connections.findIndex(connection => connection.id === connectionId);
        if (cIndex !== -1) {
            connectionIndex = cIndex;
        }
        return cIndex !== -1;
    });
    if (connectionIndex !== -1 && node) {
        node.connections.splice(connectionIndex, 1);
        subscription.publish(ON_CONNECTIONS_UPDATE); // update tree connections
    }
};

/**
 * Returns siblings (if any) of a node
 * @param {import('./types/tree').ITreeNode[]} treeNodes 
 * @param {string} nodeId 
 */
export const getSiblings = (treeNodes, nodeId) => {
    const fetchedNode = treeNodes.find(node => node.nodeId === nodeId);
    let siblings = [];
    if (fetchedNode && !fetchedNode.isStart) {
        const parentNodeId = fetchedNode.position.split('/').slice(-1)[0];
        siblings = treeNodes.filter(node => node.nodeId !== fetchedNode.nodeId && node.position.split('/').slice(-1)[0] === parentNodeId);
    }
    return siblings;
};

/**
 * Returns parent node (if any) of a node
 * @param {import('./types/tree').ITreeNode[]} treeNodes 
 * @param {string} childNodeId 
 */
export const getParentNode = (treeNodes, childNodeId) => {
    const childNode = treeNodes.find(node => node.nodeId === childNodeId);
    if (childNode && !childNode.isStart) {
        const parentNodeId = childNode.position.split('/').slice(-1)[0];
        if (parentNodeId) {
            return treeNodes.find(node => node.nodeId === parentNodeId);
        }
        return null;
    }
    return null;
};

/**
 * Returns all direct connections leading to specified node 
 * @param {import('./types/tree').ITreeNode[]} treeNodes
 * @param {string} targetNodeId
 */
export const getNodeDirectConnections = (treeNodes, targetNodeId) => {
    return treeNodes
        .flatMap(node => node.connections.map(connection => ({
            ...connection,
            sourceNodeId: node.nodeId
        })))
        .filter(connection => {
            const parentNode = getParentNode(treeNodes, connection.targetNodeId);
            return parentNode.nodeId !== connection.sourceNodeId && connection.targetNodeId === targetNodeId;
        });
};

/**
 * Returns the level of a node in tree
 * @param {import('./types/tree').ITreeNode} node
 */
export const getNodeLevel = (node) => node ? node.position.split('/').length : undefined;

/**
 * Returns all nodes in a level of tree
 * @param {import('./types/tree').ITreeNode[]} treeNodes
 * @param {string} level 
 */
export const getNodesByLevel = (treeNodes, level) => {
    const nodesInSameLevel = treeNodes.filter(node => node.position.split('/').length === level);
    return nodesInSameLevel;
};
