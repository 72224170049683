import moment from 'moment';
import momentTimezone  from 'moment-timezone';

/**
 * Converts valid string to HTML element
 * @param {string} str
 */
export const transformStringToHtml = (str) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(str, 'text/html');
    return html.body;
};

/**
 * @param {import('../modules/core/store/types/applicationMetaStore').IBreadCrumb} breadCrumb
 */
export const getBreadCrumbLeaf = (breadCrumb) => ({
    label: breadCrumb.label,
    link: null
});

/**
 * @template T
 * @param {T[]} arr
 */
export const arrayToObject = (arr) => {
    let rv = {};
    for (let key in arr) {
        rv[key] = arr[key];
    }
    return rv;
};

/**
 * Promise wrapper for requestAnimationFrame
 */
export const nextFrame = () => new Promise(requestAnimationFrame);

/**
 * Promise wrapper for setTimeOut
 * @param {number} time
 */
export const delay = (time = 1000) => new Promise((resolve) => { 
    setTimeout(() => {
        resolve(true);
    }, time);
});

/**
 * @param {string} str 
 */
export const toUpperCaseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * returns an array with unique values and removing duplicated ones via ES6 Sets
 * Note: Only array with primitive values are supported, do not use on complex arrays
 * @param {Array} arr
 */
export const getDistinctArray = (arr) => {
    const set = new Set(arr);
    return [...set];
};

/**
 * returns local time of system from UTC time
 * @param {string} utcTime 
 */
export const convertUTCToLocalTime = (utcTime, outputTimeFormat = 'DD.MM.YYYY HH:mm', inputTimeFormat = 'YYYY-MM-DDTHH:mm:ss') => {
    return moment.utc(utcTime, inputTimeFormat).local().format(outputTimeFormat);
};

export const convertUTCToDesireTime = (utcTime, timeZone ='Europe/Berlin' , timeFormat = 'DD.MM.YYYY HH:mm') => {
    try {
        const start = momentTimezone.tz(utcTime, 'UTC');
        return start.tz(timeZone).format(timeFormat);
    } catch (err) {
        return utcTime;
    }
};

export const convertModuleVersionUTCToCestTime = (utcTime) => {

    // 08.06.2023 14:09:25
    const dateTime = utcTime.split(' ');
    const dateStr = Object.values(dateTime[0].split('.')).reverse().map(v => v).join('-');

    let times = dateTime[1].split(':');
    if (times.length == 2) {
        times.push('00');
    }
    const timeStr = times.map(v => v).join(':');

    // 2023-06-08T14:09:25s
    const utcStr = `${dateStr}T${timeStr}`;

    const start = momentTimezone.tz(utcStr, 'UTC');
    return start.tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
};

/**
 * checks if a string is valid JSON
 * @param {string} text 
 */
export const isJSON = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (err) {
        return false;
    }
};
/**
 * Get first letter of each Word in a String
 * @param {string} text 
 * @returns string
 */
export const getFirstLetters = (text) => {
    if (!text) return '';
    const firstLetters = text
        .split(' ')
        .map(word => word[0])
        .join('');
    return firstLetters;
};
