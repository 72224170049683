// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { USER_RESOURCE_PATH, USER_SERVICE_ENDPOINT } from './userServiceConstants';

const userServiceConfig = createHttpServiceConfig(USER_SERVICE_ENDPOINT, USER_RESOURCE_PATH);

//-- request interceptors --//
userServiceConfig.addRequestInterceptor(authInterceptor);

export const fetchUsersService = () => userServiceConfig.http({
    method: 'GET'
});

/**
 * @param {import('../../types/userTypes').INewUser} data
 */
export const createUserService = (data) => userServiceConfig.http({
    method: 'POST',
    data
});

/**
 * @param {string} userId
 * @param {import('../../types/userTypes').IUpdateUser} data
 */
export const updateUserService = (userId, data) => userServiceConfig.http({
    method: 'PUT',
    url: `/${userId}`,
    data
});

/**
 * @param {string} userId 
 */
export const deleteUserService = (userId) => userServiceConfig.http({
    method: 'DELETE',
    url: `/${userId}`
});

/**
 * @param {string} userId 
 */
export const fetchUserByIdService = (userId) => userServiceConfig.http({
    method: 'GET',
    url: `/${userId}`
});
