// @ts-check
import { createHttpServiceConfig, authInterceptor } from '@/services/httpFactories';
import { AUTOTEST_SERVICE_ENDPOINT, MODULE_TEST_PATH } from './autoTestServiceConstants';

const autoTestServiceConfig = createHttpServiceConfig(AUTOTEST_SERVICE_ENDPOINT, MODULE_TEST_PATH);

//-- request interceptors --//
autoTestServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('../../types/applicationTypes').IApplication} data 
 */
export const createAutoTestService = (data) => autoTestServiceConfig.http({
    method: 'POST',
    data
});

/**
 * @param {string} testId
 * @param {import('../../types/applicationTypes').IApplication} data 
 */
export const updateAutoTestService = (testId, data) => autoTestServiceConfig.http({
    method: 'PATCH',
    url: `/${testId}`,
    data
});

/**
 * @param {import('./autoTestServiceTypes').IFetchApplicationParams} params
 */
export const fetchAutoTestService = (params) => autoTestServiceConfig.http({
    method: 'GET',
    params
});

/**
 * @param {string} testId
 */
export const deleteAutoTestService = (testId) => autoTestServiceConfig.http({
    method: 'DELETE',
    url: `/${testId}`
});

/**
 * @param {string} testId
 */
export const fetchAutoTestByIdService = (testId) => autoTestServiceConfig.http({
    method: 'GET',
    url: `/${testId}`
});

/**
 * @param {string} testId
 */
export const runAutoTestByIdService = (testId) => autoTestServiceConfig.http({
    method: 'POST',
    url: `/${testId}`,
    data: {}
});
