// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { DATABASE_SERVICE_ENDPOINT, TABLE_RESOURCE_PATH } from './databaseServiceConstants';

export const tableServiceConfig = {
    ...createHttpServiceConfig(DATABASE_SERVICE_ENDPOINT, ''),
    /**
     * @param {string} databaseId 
     * @param {string} [tableId]
     */
    getTableResourcePath: (databaseId, tableId = '') => `/database/${databaseId}${TABLE_RESOURCE_PATH}${tableId ? '/' + tableId : ''}`
};

//-- request interceptors --//
tableServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} databaseId 
 * @param {import('../../types/databaseTypes').INewTable} data 
 */
export const createTableService = (databaseId, data) => tableServiceConfig.http({
    method: 'POST',
    url: tableServiceConfig.getTableResourcePath(databaseId),
    data
});

/**
 * @param {string} databaseId
 */
export const fetchTablesService = (databaseId) => tableServiceConfig.http({
    method: 'GET',
    url: tableServiceConfig.getTableResourcePath(databaseId)
});

/**
 * @param {string} databaseId
 * @param {string} tableId
 * @param {import('../../types/databaseTypes').IUpdateTable} data
 */
export const updateTableService = (databaseId, tableId, data) => tableServiceConfig.http({
    method: 'PATCH',
    url: tableServiceConfig.getTableResourcePath(databaseId, tableId),
    data
});

/**
 * @param {string} databaseId
 * @param {string} tableId
 */
export const deleteTableService = (databaseId, tableId) => tableServiceConfig.http({
    method: 'DELETE',
    url: tableServiceConfig.getTableResourcePath(databaseId, tableId)
});

/**
 * @param {string} databaseId
 * @param {string} tableId
 */
export const fetchTableByIdService = (databaseId, tableId) => tableServiceConfig.http({
    method: 'GET',
    url: tableServiceConfig.getTableResourcePath(databaseId, tableId)
});
