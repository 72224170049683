<template>
  <component :is="$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
export default {
    name: 'App'
};
</script>

<style>
html {
  overflow-y: auto !important;
}
body {
  height: 100vh!important;
  background: #ffffff;
  font-size: 14px!important;
}

.drawflow .drawflow-node h1 {
  font-size: 0.85rem!important;
}
</style>
