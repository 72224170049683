// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { MODULE_TAG_RESOURCE_PATH, VERSION_CONTROL_MODULE_RESOURCE_PATH, VERSION_CONTROL_SERVICE_ENDPOINT } from './versionControlServiceConstants';

const versionControlModuleServiceConfig = {
    ...createHttpServiceConfig(VERSION_CONTROL_SERVICE_ENDPOINT, VERSION_CONTROL_MODULE_RESOURCE_PATH),
    /**
     * @param {string} moduleId
     * @param {string} [tagId]
     */
    getModuleTagResourcePath: (moduleId, tagId = '') => `/${moduleId}${MODULE_TAG_RESOURCE_PATH}${tagId ? '/' + tagId : ''}`
};

//-- request interceptors --//
versionControlModuleServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {string} moduleId
 */
export const fetchModuleTagsService = (moduleId, params) => versionControlModuleServiceConfig.http({
    method: 'GET',
    url: versionControlModuleServiceConfig.getModuleTagResourcePath(moduleId),
    params
});

/**
 * @param {string} moduleId
 * @param {string} tagId
 */
export const fetchModuleTagByIdService = (moduleId, tagId) => versionControlModuleServiceConfig.http({
    method: 'GET',
    url: versionControlModuleServiceConfig.getModuleTagResourcePath(moduleId, tagId)
});

/**
 * @param {string} moduleId 
 * @param {object} data
 */
export const createModuleTagService = (moduleId, data) => versionControlModuleServiceConfig.http({
    method: 'POST',
    url: versionControlModuleServiceConfig.getModuleTagResourcePath(moduleId),
    data
});

/**
 * @param {string} moduleId
 */
export const fetchModuleVersionsService = (moduleId) => versionControlModuleServiceConfig.http({
    method: 'GET',
    url: `/${moduleId}/deployed`
});
