// @ts-check
import { createHttpServiceConfig, authInterceptor } from '@/services/httpFactories';
import { APPLICATION_RESOURCE_PATH, APPLICATION_SERVICE_ENDPOINT } from './applicationServiceConstants';

const applicationServiceConfig = createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH);

//-- request interceptors --//
applicationServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('../../types/applicationTypes').IApplication} data 
 */
export const createApplicationService = (data) => applicationServiceConfig.http({
    method: 'POST',
    data
});

/**
 * @param {string} appId
 * @param {import('../../types/applicationTypes').IApplication} data 
 */
export const updateApplicationService = (appId, data) => applicationServiceConfig.http({
    method: 'PUT',
    url: `/${appId}`,
    data
});

/**
 * @param {import('./applicationServiceTypes').IFetchApplicationParams} params 
 */
export const fetchApplicationsService = (params) => applicationServiceConfig.http({
    method: 'GET',
    params
});

/**
 * @param {string} appId 
 */
export const deleteApplicationService = (appId) => applicationServiceConfig.http({
    method: 'DELETE',
    url: `/${appId}`
});

/**
 * @param {string} appId
 */
export const fetchApplicationByIdService = (appId) => applicationServiceConfig.http({
    method: 'GET',
    url: `/${appId}`
});
