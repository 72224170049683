import { uuid } from 'vue-uuid';
import { ON_CONNECTIONS_UPDATE, ON_PARENT_CHILD_CONNECTION_DELETE, subscription } from './treeFlowPubSub';
import { checkParentChildConnection, connectDanglingNode, deleteConnectionFromTree, getConnectionById, isDanglingNode } from './treeFlowInternal';
import { cloneDeep } from 'lodash';

//-- public utility functions --//

/**
 * Factory function for creating node object
 * @param {array} treeNodes
 * @param {object} nodeData
 * @param {object} parentNode
 */
export const generateNode = (treeNodes, nodeData, parentNode = null) => {
    let isStart = false;
    let position = '';
    if (treeNodes.length === 0) {
        isStart = true;
        position = '';
    }
    if (parentNode) {
        position = `${parentNode.position}/${parentNode.nodeId}`;
    }
    return {
        nodeId: uuid.v4(),
        position,
        type: nodeData.type,
        data: cloneDeep(nodeData.data),
        connections: [],
        isStart
    };
};

/**
 * Factory function for creating connection object
 * @param {object} node
 * @param {import('./types/tree').ICondition} condition
 */
export const generateConnection = (node, condition) => ({
    id: uuid.v4(),
    targetNodeId: node.nodeId,
    condition
});

/**
 * Adds a connection from source node to target node
 * @param {object} sourceNode
 * @param {object} targetNode
 * @param {import('./types/tree').ICondition} condition
 */
export const addConnection = (sourceNode, targetNode, condition) => {
    // avoid adding connection if connection between source and target already exists
    if (connectionExists(sourceNode, targetNode)) {
        console.info('A connection between nodes already exists!');
        return;
    }
    const isSourceDangling = isDanglingNode(sourceNode);
    const isTargetDangling = isDanglingNode(targetNode);
    if (isTargetDangling) {
        // append target to source
        connectDanglingNode(targetNode, sourceNode, condition);
    } else if (isSourceDangling) {
        // append source to target
        connectDanglingNode(sourceNode, targetNode, condition);
    } else {
        // otherwise simply add connection
        sourceNode.connections.push(generateConnection(targetNode, condition));
        subscription.publish(ON_CONNECTIONS_UPDATE); // notify treeConnections component
    }
};

/**
 * Removes specified connection from a node
 * @param {array} treeNodes
 * @param {string} connectionId
 */
export const removeConnection = (treeNodes, connectionId) => {
    const isParentChildConnection = checkParentChildConnection(treeNodes, connectionId);
    if (isParentChildConnection) {
        const connection = getConnectionById(treeNodes, connectionId);
        subscription.publish(ON_PARENT_CHILD_CONNECTION_DELETE, connection.targetNodeId); // trigger event on subscribers
    }
    deleteConnectionFromTree(treeNodes, connectionId);
};

/**
 * Checks if a connection between source and target node exists
 * @param {object} sourceNode 
 * @param {object} targetNode 
 */
const connectionExists = (sourceNode, targetNode) => {
    return sourceNode.connections.some(connection => connection.targetNodeId === targetNode.nodeId);
};
