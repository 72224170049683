<template>
  <div>
    <ModuleEditorHeader />
    <div class="layout-content">
      <slot />
    </div>
  </div>
</template>

<script >
import ModuleEditorHeader from '@/modules/builder/components/application/ModuleEditorHeader.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  ModuleEditorHeader
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.layout-content {
    margin-top: 0.75rem;
    height: calc(100vh - 53px)
}
</style>
